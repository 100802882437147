import React from "react"

const Mighty = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M393.1 275.9c-3.9-6.6-10-10.7-19.2-10.7-20.7 0-34.9 20.2-34.9 45.2 0 1.2 0 2.3.1 3.4-1.3 12.3-6.5 24.3-14.6 24.3-4.7 0-5.1-5.5-5.1-11.5v-61.8h-30.7v7.7c3.4 0 5.1 3.4 5.1 6v30.7c0 9.4-3.8 29-14.5 29-4.7 0-5.1-5.5-5.1-11.5v-30.3c0-24.7-14.5-32.4-29-32.4-9.6 0-16.2 4.9-20.4 11.8-5.5-8.7-14.7-11.8-24-11.8-6.4 0-11.4 2.2-15.3 5.6v-4.8h-30.7v7.7c3.4 0 5.1 3.4 5.1 6v76.7h25.6v-58.8c0-11.1 2.6-18.7 10.2-18.7 5.1 0 8.5 5.1 8.5 11.7v65.8h25.6v-58.8c0-11.1 3.4-18.7 10.2-18.7 5.1 0 8.5 5.1 8.5 11.7v43.7c0 12.8 6 23 21.3 23 12 0 20-6.9 25.2-16.1 2.1 7.7 7.4 16.1 20.9 16.1 13.9 0 22.8-10.5 27.9-21.9 5.8 12.9 16.6 21.5 30.1 21.5 9.2 0 15.3-4 19.2-10.7v9.8c0 17.5-3 27.7-16.2 27.7-8.9 0-14.5-8.5-14.5-17.5h-20.9c0 24.3 13.6 37.1 35.4 37.1 32 0 40.1-23 40.1-49.4V279c0-2.6 1.7-6 5.1-6v-7.7h-29v10.6zm-14.4 64c-8 0-14.4-12.2-14.4-29.4s7.3-29.4 15.3-29.4 12.7 12.2 12.7 29.4c-.1 17.1-5.6 29.4-13.6 29.4zm241.5-74.2v36.4c-2.4 26.6-9.7 38.6-16.6 38.6-6.4 0-8.5-8.5-8.5-25.6v-49.4H570V310c0 5.1-4.3 28.1-14.5 28.1-3.4 0-6-4.3-6-15.3v-69H570v-14.5h-20.5V218h-29.8v7.7c3.4 0 5.1 3.4 5.1 6v77.5c0 9.4-3.8 29-14.5 29-4.7 0-5.1-5.5-5.1-11.5v-30.3c0-24.7-14.5-32.4-29-32.4-6.4 0-11.4 2.2-15.3 5.6V218H431v7.7c3.4 0 5.1 3.4 5.1 6v123.6h24.7v-58.8c0-11.1 3.4-18.7 10.2-18.7 5.1 0 8.5 5.1 8.5 11.7v40.3c0 6.8.9 26.4 21.3 26.4 11.9 0 19.8-6.8 25-15.9 1.9 7.6 6.9 15.9 20.2 15.9 13.3 0 21.2-8.5 25.9-19.1 4.6 13.8 16.2 19.1 25.3 19.1 11.7 0 18.6-6.3 23-15.3v14.4c0 17.5-3 27.7-16.2 27.7-8.9 0-14.5-8.5-14.5-17.5h-20.9c0 24.3 13.6 37.1 35.4 37.1 32 0 40.1-23 40.1-49.4v-73.7c0-2.6 1.7-6 5.1-6v-7.7h-29z"></path>
    <circle cx="306.1" cy="235.5" r="17.5"></circle>
  </svg>
)

export default Mighty
