import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Logogal from "../components/logogal"
import {motion} from "framer-motion"


export default function Wordmarks() {
  const [isColor, setIsColor] = useState(false)

  useEffect(() => {
    if (document.body.classList.contains("whiteTheme")) {
      document.body.classList.replace("whiteTheme", "blackTheme")
    } else {
      document.body.classList.replace("blackTheme", "whiteTheme")
    }
  }, [isColor])

  useEffect(() => {
    console.log(isColor)
  }, [isColor])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="Lance – Wordmarks"></meta>

        <title>Lance / Wordmarks</title>
        <link rel="preload" href="/fonts/fonts.css" as="style"></link>
        <body className="whiteTheme" />
      </Helmet>
      <>
        <motion.button
          className={isColor ? "layout bg-gradient-to-tr from-bankjoy to-purple-600 sticky rounded-full text-white px-3 py-1 text-base font-medium top-6 mb-1 left-12 z-50 block mx-auto shadow-2xl" :"block shadow-2xl mx-auto sticky rounded-full px-3 bg-black py-1 text-white text-base font-medium top-6 mb-1 left-12 z-50 "}
          onClick={() => setIsColor(!isColor)}
        >
          {!isColor ? "Black & White" : "Show Colors"}
        </motion.button>
        <Logogal isColor={isColor} />
      </>
    </>
  )
}
