import React from "react"
import Kuala from "./svg/kuala"
import Voiceflow from "./svg/voiceflow"
import Signwell from "./svg/signwell"
import Bankjoy from "./svg/bankjoy"
import Panchos from "./svg/panchos"
import Muffinsroom from "./svg/muffinsroom"
import Channels from "./svg/channels"
import Backdrop from "./svg/backdrop"
import Quimade from "./svg/quimade"
import North from "./svg/north"
import Melimelo from "./svg/melimelo"
import Beaugal from "./svg/beaugal"
import Swipp from "./svg/swipp"
import Mighty from "./svg/mighty"
import Mailbee from "./svg/mailbee"
import Gretel from "./svg/gretel"
import Cg from "./svg/cg"
import Juicybeast from "./svg/juicybeast"
import Thatcopy from "../components/svg/thatcopy"
import Citizens from "./svg/citizens"
import Fratelli from "./svg/fratelli"
import Tailwise from "./svg/tailwise"
import Mailgrid from "./svg/mailgrid"
import Dieming from "./svg/dieming"
import Apostrophe from "./svg/apostrophe"
import Casper from "./svg/casper"
import Pinjour from "./svg/pinjour"
import Kaymana from "./svg/kaymana"
import Mudddy from "./svg/mudddy"
import Bartenders from "./svg/bartenders"
import Kendies from "./svg/kendies"
import { motion } from "framer-motion"
import Chimney from "./svg/chimney"
import Thistinct from "./svg/thistinct"
import Athlete from "./svg/athlete"
import Lapine from "./svg/lapine"
import Storymade from "./svg/storymade"
import Logo from "./logo"
import Grocery from "./svg/grocery"
import Thomas from "./svg/thomas"

export default function Logogal({isColor}) {

  const item = {
    show: {
      opacity: 1,
      y: 0,
      transition: { bounce: 0, stiffness: 70, type: "spring" },
    },
    hide: { opacity: 0, y: -20 },
    tap: { scale: 0.9 },
    hover: {
      scale: 1.2,
      transition: { ease: "easeInOut", duration: 0.2 },
    },
  }
  return (
    <motion.div
      className="grid gap-y-2 mb-12 px-2 md:mb-28 gap-x-12 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 md:gap-x-24 md:gap-y-12 mt-4 md:mt-12 justify-center"
      initial="hide"
    >
      <motion.div whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-voiceflow hover:scale-150 duration-300 transition-transform transform"}>
        <Voiceflow />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-bankjoy hover:scale-150 duration-300 transition-transform transform"}>
        <Bankjoy />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Chimney />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-channels hover:scale-150 duration-300 transition-transform transform"}>
        <Channels />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-thistinct hover:scale-150 duration-300 transition-transform transform"}>
        <Thistinct />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-athlete hover:scale-150 duration-300 transition-transform transform"}>
        <Athlete />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-north hover:scale-150 duration-300 transition-transform transform"}>
        <North />
      </motion.div>


      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Storymade /> 
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-lapine hover:scale-150 duration-300 transition-transform transform"}>
        <Lapine />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-gp hover:scale-150 duration-300 transition-transform transform"}>
        <Grocery />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-real hover:scale-150 duration-300 transition-transform transform"}>
        <Thomas />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Thatcopy />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-kuala hover:scale-150 duration-300 transition-transform transform"}>
        <Kuala />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-jb hover:scale-150 duration-300 transition-transform transform"}>
        <Juicybeast />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Cg />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-lapostrophe hover:scale-150 duration-300 transition-transform transform"}>
        <Apostrophe />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Mailbee />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-gretel hover:scale-150 duration-300 transition-transform transform"}>
        <Gretel />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-panchos hover:scale-150 duration-300 transition-transform transform"}>
        <Panchos />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Mighty />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Beaugal />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Citizens />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-fratelli hover:scale-150 duration-300 transition-transform transform"}>
        <Fratelli />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Mailgrid />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Dieming />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-casper hover:scale-150 duration-300 transition-transform transform"}>
        <Casper />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-purple-600 hover:scale-150 duration-300 transition-transform transform"}>
        <Swipp />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-kaymana hover:scale-150 duration-300 transition-transform transform"}>
        <Kaymana />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Mudddy />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Muffinsroom />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-tailwise hover:scale-150 duration-300 transition-transform transform"}>
        <Tailwise />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Quimade />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Melimelo />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Backdrop />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-pinjour hover:scale-150 duration-300 transition-transform transform"}>
        <Pinjour />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-black hover:scale-150 duration-300 transition-transform transform"}>
        <Bartenders />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-signwell hover:scale-150 duration-300 transition-transform transform"}>
        <Signwell />
      </motion.div>
      <motion.div  whileTap={item.tap} className= {isColor ? "text-white hover:scale-150 duration-300 transition-transform transform" : "text-kendies hover:scale-150 duration-300 transition-transform transform"}>
        <Kendies />
      </motion.div>
    </motion.div>
  )
}
